import React from 'react';
import { ShyftWx } from 'shyftwx';

/**
 * Calls the Shyftwx component from the ShyftWx library
 * @returns {JSX.Element}
 */
export const Viewport = () => {
    return <ShyftWx url="https://api.shyftwx.com/v1/datasets" />;
};

export default Viewport;
