import React from 'react';
import Viewport from './components/Viewport'
import { Container } from '@material-ui/core'

/**
 * The viewer app
 * @returns {JSX.Element}
 */
function App() {
  
    return (
      <Container className="App">
          <Viewport />
      </Container>
    );
  }
  
  export default App;
